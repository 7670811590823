import React from 'react'
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import CustomLink from './CustomLink.js'

class BoilerplateAbout extends React.Component {
  render() {
    return (
        <MDBContainer>
          <MDBRow className="mb-5">
            <MDBCol>
              {renderRichText(
                this.props.about,
                {
                  renderNode: {
                    [BLOCKS.HEADING_2]: (node, children) => <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large">{children}</h2>,
                    [BLOCKS.HEADING_3]: (node, children) => <p className="font-alt font-w-400 letter-spacing-1 text-medium">{children}</p>,
                    [BLOCKS.PARAGRAPH]: (node, children) => <p className="font-w-400 letter-spacing-1 text-medium" style={{ whiteSpace: 'pre-wrap' }}>{children}</p>,
                    [INLINES.HYPERLINK]: (node, children) => (
                      <CustomLink link={node.data.uri}>{children}</CustomLink> 
                    ),
                    [BLOCKS.EMBEDDED_ASSET]: (node, children) => (  
                      /* need to change style to class and cater for mobile */
                      <img
                        style={{
                          display: 'block',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                          width: '50%',
                          paddingTop: '10px', 
                          paddingBottom: '20px' 
                        }}
                        src={`${node.data.target.fields.file['en-US'].url}?w=400&q=90`}
                        alt={node.data.target.fields.title}
                      />
                    ),
                  },
                }
              )}
            </MDBCol>
          </MDBRow>
        </MDBContainer>
    )
  }
}
export default BoilerplateAbout